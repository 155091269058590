import Joi from 'joi';

const createProductSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  upsells: Joi.array().items(
    Joi.object({ product_id: Joi.string(), sku: Joi.string() })
  ),
  use_mixed_template: Joi.boolean(),
  style_ids: Joi.array().label('Styles').min(1).required(),
  only_upsell: Joi.boolean(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code'),
  label_slots: Joi.array()
    .min(1)
    .label('Labels')
    .items(
      Joi.object({
        options: Joi.array()
          .min(1)
          .items({
            label_id: Joi.string().required(),
            quantity: Joi.number().min(1).label('Label Quantity').required(),
            endcap_text: Joi.string().allow(null),
            endcap_is_footer: Joi.boolean().allow(null),
            endcap_print_mode: Joi.string().allow(null),
            material_ids: Joi.array()
              .min(1)
              .label('Label Materials')
              .required(),
            coordinates: Joi.array().items(
              Joi.object({
                x: Joi.number().required(),
                y: Joi.number().required(),
                height: Joi.number().required(),
                width: Joi.number().required()
              })
            )
          })
      })
    )
    .required(),
  template_selections: Joi.array()
    .min(1)
    .label('PDF Elements')
    .items(
      Joi.object({
        material_id: Joi.string().required().label('Material ID'),
        left_icon_id: Joi.string().required().label('Left Icon'),
        left_print_key: Joi.string().required().label('Left Color'),
        left_text_id: Joi.string().required().label('Left Text'),
        middle_icon_id: Joi.string().required().label('Middle Icon'),
        middle_print_key: Joi.string().required().label('Middle Color'),
        additional_text_id: Joi.string().required().label('Additional Text'),
        footer_type_id: Joi.string().required().label('Footer Type'),
        template_type: Joi.string()
          .allow('clothing', 'non-clothing', 'mixed')
          .required()
          .label('Template Type')
      })
    )
});

const updateProductSchema = Joi.object({
  product_id: Joi.string().label('Product ID').required(),
  system_name: Joi.string().label('System Name').required(),
  upsells: Joi.array().items(
    Joi.object({ product_id: Joi.string(), sku: Joi.string() })
  ),
  style_ids: Joi.array().label('Styles').min(1).required(),
  use_mixed_template: Joi.boolean(),
  only_upsell: Joi.boolean(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code'),
  label_slots: Joi.array()
    .min(1)
    .label('Labels')
    .items(
      Joi.object({
        product_label_slot_id: Joi.string().optional(),
        options: Joi.array()
          .min(1)
          .items({
            label_id: Joi.string().required(),
            quantity: Joi.number().min(1).label('Label Quantity').required(),
            endcap_text: Joi.string().allow(null),
            endcap_is_footer: Joi.boolean().allow(null),
            endcap_print_mode: Joi.string().allow(null),
            material_ids: Joi.array()
              .min(1)
              .label('Label Materials')
              .required(),
            coordinates: Joi.array().items(
              Joi.object({
                x: Joi.number().required(),
                y: Joi.number().required(),
                height: Joi.number().required(),
                width: Joi.number().required()
              })
            )
          })
      })
    )
    .required(),
  template_selections: Joi.array()
    .min(1)
    .label('PDF Elements')
    .items(
      Joi.object({
        material_id: Joi.string().required().label('Material ID'),
        left_icon_id: Joi.string().required().label('Left Icon'),
        left_print_key: Joi.string().required().label('Left Color'),
        left_text_id: Joi.string().required().label('Left Text'),
        middle_icon_id: Joi.string().required().label('Middle Icon'),
        middle_print_key: Joi.string().required().label('Middle Color'),
        additional_text_id: Joi.string().required().label('Additional Text'),
        footer_type_id: Joi.string().required().label('Footer Type'),
        template_type: Joi.string()
          .allow('clothing', 'non-clothing', 'mixed')
          .required()
          .label('Template Type')
      })
    )
});

export default {
  createProductSchema,
  updateProductSchema
};
